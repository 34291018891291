<template>
  <div style="position: fixed; z-index: 1000001; bottom: 0; right: 0; border-radius: 10px 0 0 0; padding: 10px; background-color: #ff0000; opacity: 0.5; cursor: pointer" @click="toggle">
    Dev Toolbar
  </div>
  <div v-if="show" style="text-align: left; position: fixed; z-index: 1000000; bottom: 0px; padding: 30px; background-color: #ffffff; opacity: 0.8; border-top: 3px #000000 solid; width: 100%">
    Pages:
    <NuxtLink to="/">
      Index
    </NuxtLink> |
    <NuxtLink to="/sitemap">
      Sitemap
    </NuxtLink> |
    Product Page:
    <NuxtLink to="/ferienwohnungen/rpo/norderstedt/1000499/haus-im-wald.php">
      Travanto OB
    </NuxtLink> &#x2BC1;
    <NuxtLink to="/ferienwohnungen/rpo/hamburg/1001169/robin-uab-haus.php">
      Travanto UAB &#x2BC1;
    </NuxtLink>
    <NuxtLink to="/ferienwohnung/rpo/ft/st-peter-ording/416888/kajuete-sieben-im-immenseeweg-bark-fuer-3-personen.php">
      Feratel &#x2BC1;
    </NuxtLink>
    <NuxtLink to="/ferienwohnung/rpo/ft/niendorf/427160/appartementhaus-mueller-zweiraumapp-e20.php">
      Feratel 2 &#x2BC1;
    </NuxtLink>
    <NuxtLink to="/ferienwohnung/rpo/sc/ulsnis/829106/ferienhaus-ferienhaus-alte-schmiede-ulsnis.php">
      Secra &#x2BC1;
    </NuxtLink>
    <NuxtLink to="/ferienwohnung/rpo/sc/lechbruck-am-see/830012/ferienhaus-ferienhaus-sefi-lechbruck-am-see.php">
      Secra 2 &#x2BC1;
    </NuxtLink>
    <NuxtLink to="/ferienwohnung/rpo/sc/soltau/829529/ferienwohnung-ferienpark-auf-dem-simpel-schlaf-fass-1-soltau.php">
      Secra 3 &#x2BC1;
    </NuxtLink>
    <NuxtLink to="/ferienwohnung/rpo/we/binz-ostseebad/2005611/villa-strandidyll-apartment-4-2-balkone-mit-meerblick-strandidyll-4-typ-3.php">
      Wildeast &#x2BC1;
    </NuxtLink>
    <NuxtLink to="/ferienwohnung/rpo/we/petersdorf-auf-fehmarn/2010239/kw5-magellan.php">
      Wildeast 2
    </NuxtLink> |
    <NuxtLink to="/wishlist">
      WishList
    </NuxtLink>
    <br><br>
    Tools:
    <a :href="`/backend/api/pages?path=${ useRoute().path }`" target="_blank">API for Current page</a> |
    <a href="/backend/cronjobs/CreateUrls" target="_blank">Create URLs</a>
    <br><br>
    Dev Playground:
    <NuxtLink to="/dev-playground/pageProduct">
      Product
    </NuxtLink> |
    <NuxtLink to="/dev-playground/pageRues">
      Rues
    </NuxtLink> |
    <NuxtLink to="/urlaubsmagazin/familienurlaub/">
      Content Page
    </NuxtLink> |
    <NuxtLink to="/dev-playground/page1">
      Page 1
    </NuxtLink> |
    <NuxtLink to="/dev-playground/page2">
      Page 2
    </NuxtLink> |
    <NuxtLink to="/dev-playground/counter">
      Counter (w. Pinia)
    </NuxtLink> |
    <NuxtLink to="/dev-playground/prerendered">
      Prerendered
    </NuxtLink> |
    <NuxtLink to="/dev-playground/tailwind">
      Tailwind
    </NuxtLink>|
    <NuxtLink to="/test.json">
      test.json (NuxtLink)
    </NuxtLink> |
    <a href="/test.json">test.json (a)</a>
    <br>
    <a @click="triggerSearchRequestEvent">sendSearchRequestEvent (GTM)</a> |
    <a @click="triggerSearchResultsEvent">sendNoSearchResultsDynamicEvent (GTM)</a> |
    <a @click="triggerRecommendationImpressionEvent">sendRecommendationImpressionEvent (GTM) | </a>
    <a @click="triggerPurchaseEvent">sendTriggerPurchaseEvent (GTM) | </a>
    <a @click="triggerPromotionClickEvent">sendPromotionClickEvent (GTM) | </a>
    <a @click="triggerProductImpressionEvent">sendProductImpressionEvent (GTM) | </a>
    <a @click="triggerProductDetailViewEvent">sendProductDetailViewEvent (GTM) | </a>
    <a @click="triggerProductClickEvent">sendProductClickEvent (GTM) | </a>
    <a @click="triggerPriceCalculationFailureEvent">sendPriceCalculationFailureEvent (GTM) | </a>
  </div>
</template>
<script setup>
const show = ref(false)
const toggle = () => {
  show.value = !show.value
}

function triggerSearchRequestEvent () {
  const { $gtm } = useNuxtApp()
  $gtm.searchRequests.sendSearchRequestEvent('Just a test location')
}
function triggerSearchResultsEvent () {
  const { $gtm } = useNuxtApp()
  $gtm.searchResults.sendNoSearchResultsDynamicEvent()
}
function triggerRecommendationImpressionEvent () {
  const { $gtm } = useNuxtApp()
  $gtm.inquiriesModule.sendRecommendationImpressionEvent()
}
function triggerPurchaseEvent () {
  const { $gtm } = useNuxtApp()
  $gtm.inquiriesModule.sendPurchaseEvent()
}
function triggerPromotionClickEvent () {
  const { $gtm } = useNuxtApp()
  $gtm.inquiriesModule.sendPromotionClickEvent()
}
function triggerProductImpressionEvent () {
  const { $gtm } = useNuxtApp()
  $gtm.productsModule.sendProductImpressionEvent()
}
function triggerProductDetailViewEvent () {
  const { $gtm } = useNuxtApp()
  $gtm.productsModule.sendProductDetailViewEvent()
}
function triggerProductClickEvent () {
  const { $gtm } = useNuxtApp()
  $gtm.productsModule.sendProductClickEvent()
}
function triggerPriceCalculationFailureEvent () {
  const { $gtm } = useNuxtApp()
  $gtm.pricesModule.sendPriceCalculationFailureEvent()
}
</script>
